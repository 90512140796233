import './App.css';
import Gallery from './Gallery';
import CarPicture from './images/car-bg.jpg';
import CarPictures from './CarPictures';
import { Container, Row, Col, ListGroup, Card, Image } from 'react-bootstrap';


function App() {
  return (
    <div className="wrapper">
      <header className="header">
        <div className='car vw-100 min-vh-100'></div>
        <Container className='min-vh-100 vw-100'>
          <Row>
            <Col>
              <div className="d-flex align-content-between flex-wrap min-vh-100 p-20">
                <div className="w-100 align-content-center text-center h-10 logo">
                  ACE Driving School
                </div>

                <div className="boxes w-100 mb-50">
                  <Row>
                    <Col sm={4}>
                      <Card className='text-center box bg-white border-0'>
                        <Card.Header className='bg-transparent border-0'>
                          <Card.Subtitle className='letter mb-4'>L</Card.Subtitle>
                          <Card.Title>Learn to Drive</Card.Title>
                        </Card.Header>
                        <Card.Body className='bg-light'>
                          <Row>
                            <Col>
                              <a href='tel:+447926387156' className='text-dark'><b>07926 387 156</b></a>
                            </Col>
                            <Col>
                              <a href='mailto:tina@boggins.org' className='text-dark'><b>tina@boggins.org</b></a>
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col sm={4}>
                      <Card className='text-center box bg-white border-0'>
                        <Card.Header className='bg-transparent border-0'>
                          <Card.Subtitle className='letter mb-4'>?</Card.Subtitle>
                          <Card.Title>About Us</Card.Title>
                        </Card.Header>
                        <Card.Body className='bg-light'>
                          <a href='#about' className='text-dark'><b>Find out all about ACE Driving School</b></a>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col sm={4}>
                      <Card className='text-center box bg-white border-0'>
                        <Card.Header className='bg-transparent border-0'>
                          <Card.Subtitle className='letter mb-4 pass'>P</Card.Subtitle>
                          <Card.Title>Pass Gallery</Card.Title>
                        </Card.Header>
                        <Card.Body className='bg-light'>
                          <a href='#gallery' className='text-dark'><b>See our many happy passers!</b></a>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                </div>
              </div>

            </Col>
          </Row>
        </Container>
        <div className='road vw-100'>
          <div className='stripes'></div>
        </div>
      </header>
      <main className='main'>
        <section id='about'>
          <Container className='p-5'>
            <Row>
              <Col>
                <h1 className='mb-5 text-center'>About ACE Driving School</h1>

                <p>
                  Here at ACE Driving our mission is to get you behind the wheel;
                  in the driving seat and on the road to being a great skilled,
                  safe and responsible driver.
                </p>

                <p>
                  We're taking a fresh approach to driver training by being client-centered
                  and coach based.
                </p>

                <p>
                  We tailor our teaching methods to the learning styles of each client ensuring
                  a personalised effective learning experience.
                </p>

                <p>
                  We enhance this by acting as your coach, we provide the support, guidance and
                  feedback while encouraging you to take an active part in your advancement towards
                  a successful pass and onto a skilled, responsible driver of the future.
                </p>
              </Col>
            </Row>
          </Container>
        </section>

        <section id='gallery'>
            <Row>
              <Col className='mb-5'>
                <h1 className='text-center p-5'>Gallery</h1>

                <Gallery slides={ CarPictures } options={{loop: true, container: '.embla'}} />
              </Col>
            </Row>
        </section>

        <section>
          <Container>
            <Row>
              <Col className='p-5'>
                <h1>What we offer</h1>

                <Row>
                  <Col xs={12} md={6}>
                    <ul className='fancy-ticks mt-5 m-0-md'>
                      <li><p>1-2-1 driving tuition.</p></li>
                      <li><p>A<b> fully qualified government approved</b> driving instructor <b>(ADI)</b></p></li>
                      <li><p>The <b>full attention of your ADI</b>; no distractions.</p></li>
                    </ul>
                  </Col>
                  <Col xs={12} md={6}>
                    <ul className='fancy-ticks mt-5'>
                      <li><p>Roadside theory with an emphasis on team based practical driving. <b>Where theory meets real life, true learning begins</b>.</p></li>
                      <li><p>A fully equipped <b>manual car</b> with dual controls.</p></li>
                      <li><p>A <b>calm</b>, <b>patient</b> and <b>friendly</b> learning environment.</p></li>
                    </ul>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} className='d-flex justify-content-center'>
                    <ul className='fancy-ticks mb-5'>
                      <li><p>Use of our <b>dedicated driving app</b> to view your lessons, payments, progress and record your reflective logs.</p></li>
                    </ul>
                  </Col>
                </Row>
                <Image src={CarPicture} className='rounded w-100' />
              </Col>
            </Row>
          </Container>
        </section>
      </main>
      <footer>
        <section className='footer text-light text-center bg-dark p-5'>
          <Container>
            <Row>
              <Col xs={12} md={4} className='mb-5'>
                <h3>ACE Driving School</h3>
                <p>
                  Our mission is to make you a skilled, safe, and responsible driver.
                  With a client-centered approach, we tailor your learning experience to your style and actively guide you toward success.
                </p>
              </Col>
              <Col xs={12} md={4} className='mb-5'>
                <h3>Useful links</h3>
                <ListGroup>
                  <ListGroup.Item className='border-0 bg-dark p-0 mb-2'>
                    <a rel="nofollow noreferrer" href="https://www.gov.uk/book-theory-test" target="_blank" className='text-light'>
                      Book Your UK Theory Driving Test
                    </a>
                  </ListGroup.Item>
                  <ListGroup.Item className='border-0 bg-dark p-0 mb-2'>
                    <a href="https://www.gov.uk/book-driving-test" rel="nofollow noreferrer" target="_blank" className='text-light'>
                      Book Your UK Practical Driving Test
                    </a>
                  </ListGroup.Item>
                  <ListGroup.Item className='border-0 bg-dark p-0 mb-2'>
                    <a href="https://readytopass.campaign.gov.uk/driving-test-nerves" rel="nofollow noreferrer" target="_blank" className='text-light'>
                      Managing your driving test nerves
                    </a>
                  </ListGroup.Item>
                  <ListGroup.Item className='border-0 bg-dark p-0 mb-2'>
                    <a href="https://www.moneysavingexpert.com/insurance/car-insurance/learner-drivers" rel="nofollow noreferrer" target="_blank" className='text-light'>
                      Learner driver insurance guide
                    </a>
                  </ListGroup.Item>
                  <ListGroup.Item className='border-0 bg-dark p-0 mb-2'>
                    <a href="https://www.wearemarmalade.co.uk/young-driver-insurance" rel="nofollow noreferrer" target="_blank" className='text-light'>
                      Young driver insurance
                    </a>
                  </ListGroup.Item>
                </ListGroup>
              </Col>

              <Col xs={12} md={4} className='mb-5'>
                <h3>Contact</h3>

                <p>
                  07926 387 156
                </p>

                <p>
                  tina@boggins.org
                </p>
              </Col>
            </Row>
          </Container>
        </section>
        <section className='d-flex justify-content-center align-content-center colophon bg-black text-light text-center p-2'>
          &copy; - ACE Driving School
        </section>
      </footer>

    </div>
  );
}

export default App;
