import React from 'react'
import AutoScroll from 'embla-carousel-auto-scroll'
import useEmblaCarousel from 'embla-carousel-react'
import { Image } from 'react-bootstrap'
import './Gallery.css'

export default function Gallery({ slides, options }) {
  const [emblaRef, emblaApi] = useEmblaCarousel(options, [AutoScroll({ playOnInit: true })])

  return (
    <section className="embla">
      <div className="embla__viewport" ref={emblaRef}>
        <div className="embla__container">
          {slides.map((slide, i) => (
            <div className="embla__slide" key={i}>
              <div className="embla__slide__number">
                <Image src={slide} className='w-100' />
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}
