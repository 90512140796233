import Slide1 from './images/slide1.jpg';
import Slide2 from './images/slide2.jpg';
import Slide3 from './images/slide3.jpg';
import Slide4 from './images/slide4.jpg';
import Slide5 from './images/slide5.jpg';

const Slides = [
  Slide1,
  Slide2,
  Slide3,
  Slide4,
  Slide5
]

export default Slides;